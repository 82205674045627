/*
    various boxes
*/
import React from 'react';

import { GatsbyLink } from '../components/linkd';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';


interface ProductBoxProps {
  maintitle: string,
  href?: string,
  to?: string,
  linktitle?: string,
  maincolor?: string,
  announce?: string,
  children: React.ReactNode,
}

export const ProductBox = (props: ProductBoxProps) => {
  return(
    <Card sx={{margin: '0.5em 0.3em', maxWidth: '50%', minWidth: '100px'}}>
      <Box mx={1} flexDirection="row">
        <Box display='flex' flexWrap='wrap' justifyContent='space-between'>
          <Typography variant='h6'>
            {props.maintitle}
          </Typography>
          { (props.href) &&
            <Button size='small' href={props.href} sx={{margin: 1}} variant='outlined'>
              {props.linktitle || 'info'}
            </Button>
          }
          { (props.to) &&
            <Button size='small' component={GatsbyLink} to={props.to} sx={{margin: 1}} variant='outlined'>
              {props.linktitle || 'info'}
            </Button>
          }

        </Box>

        { (props.announce) &&
        <Card className='gradsite2'><Box display='flex' justifyContent='center'>
          {props.announce}
        </Box></Card>
        }

        <Box>
          {props.children}
        </Box>

      </Box>
    </Card>
)}

